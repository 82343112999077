.mobilepush-inapp-actvity {
	#summary-hub-spokes {
		.slds-card {
			.scm-card-icon {
				svg {
					height: 20px;
					width: 20px;

					path.st0 {
						fill: black;
					}
				}
			}
		}

		.hub-spoke {
			.slds-grid {
				.slds-col:first-child {
					padding-right: 0px;
				}
			}
		}
	}
}
