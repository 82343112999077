.mobilepush-inapp-actvity {
	.slds-vertical-tabs.slds-vertical-tabs__hub-and-spoke {
		height: 100vh;

		.slds-vertical-tabs__nav {
			.slds-vertical-tabs__nav-item:first-child {
				.slds-vertical-tabs__nav-item-icon-container {
					margin-bottom: 5px;

					svg {
						height: 48px;
						width: 48px;
					}
				}
			}
		}
	}
}
