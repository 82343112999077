[data-view="messageConfiguration"] {
	.expired {
		color: #c23934;
		text-transform: uppercase;
	}

	#mobile-app-icon {
		width: 20px;
		height: 20px;
		margin-top: -1px;
		margin-right: 5px;
	}
}

#messageConfiguration {
	.heading {
		text-transform: uppercase;
		margin-bottom: 10px;
		color: $default-subheading-color;
		font-weight: 300;
	}

	#mobile-application-container {
		.slds-combobox_container {
			width: 400px;
		}

		.slds-icon_container {
			&.slds-icon-utility-check {
				color: #0070d2;
			}
		}

		.disabled-text {
			color: #999999;
		}

		.expired {
			color: #c23934;
			text-transform: uppercase;
		}
	}

	.active-message-timeframe-container {
		margin-top: 30px;

		.radio-container {
			display: flex;
			flex-direction: row;
			margin-bottom: 25px;
			
			input[type="radio"] {
				cursor: pointer;
			}
			
			& > label {
				margin-left: 10px;
				margin-top: -3px;
				
				& > div {
					display: flex;
					flex-direction: column;
					
					& > span {
						cursor: pointer;
					}
				}
			}
			
			.slds-text-heading {
				font-size: 0.9rem;
			}
		}

		.custom-timeframe-scheduler-container {
			margin-top: 10px;

			&.hidden {
				display: none;
			}

			.date-time-picker {
				margin-bottom: 10px;

				input {
					&::placeholder {
						color: $default-heading-color;
						opacity: 1;
					}

					&:-ms-input-placeholder {
						color: $default-heading-color;
					}

					&::-ms-input-placholder {
						color: $default-heading-color;
					}
				}
			}

			.timezone-picker {
				input {
					&::placeholder {
						color: $default-heading-color;
						opacity: 1;
					}

					&:-ms-input-placeholder {
						color: $default-heading-color;
					}

					&::-ms-input-placholder {
						color: $default-heading-color;
					}
				}

				.desc {
					color: $default-subheading-color;
					font-size: $default-label-font-size;
					margin-top: 0.125rem;
				}
			}

			.slds-input {
				/* Override slds-visual-picker's input styling */
				width: 100%;
				position: relative;
				height: initial;
				border: 1px solid #dddbda;
				clip: initial;
				overflow: initial;
				margin: 0;
				padding: 0 1rem 0 0.75rem;
			}

			#timezone-dropdown-listbox {
				/* Override slds-visual-picker's span tag styling */
				.slds-media {
					display: flex;

					.slds-media__figure {
						display: inline-block;
					}

					.slds-media__body {
						display: inline-block;
					}
				}
			}
		}
		
		.dynamic-timeframe-container {
			.container-body {
				display: flex;
				
				> .slds-form-element {
					min-width: 48px;
				}
				
				.components-container {
					display: flex;
					flex-wrap: wrap;
					margin-top: 0.5rem;
					
					.break {
						flex-basis: 100%;
						height: 0;
					}
					
					.controls {
						display: flex;
						
						.slds-form-element:first-child { // Input field
							width: 35%;
							margin-right: 0.5rem;
							
							/* Chrome, Safari, Edge, Opera */
							input::-webkit-outer-spin-button,
							input::-webkit-inner-spin-button {
								-webkit-appearance: none;
								margin: 0;
							}
							
							/* Firefox */
							input[type=number] {
								-moz-appearance: textfield;
							}
						}
						
						.slds-form-element:last-child { // Combobox
							width: 40%;
						}
					}
					
					.error-text {
						color: #c23934;
					}
				}
			}
		}
	}
}
