#deliveryOptions {
	.help-bubble {
		margin-left: 3px;
	}
	
	.message-priority {
		.container-body {
			display: flex;
			align-items: center;

			.message-priority-counter-container {
				margin-left: 10px;
				width: 125px; // Default width of number type input
			}
		}
	}
	
	.delay-display {
		.container-body {
			display: flex;
			
			> .slds-form-element {
				min-width: 48px;
			}
			
			.components-container {
				display: flex;
				flex-wrap: wrap;
				margin-left: 1rem;
				
				.break {
					flex-basis: 100%;
					height: 0;
				}
				
				.controls {
					display: flex;
					
					.slds-form-element:first-child { // Input field
						width: 25%;
						margin-right: 0.5rem;
						
						/* Chrome, Safari, Edge, Opera */
						input::-webkit-outer-spin-button,
						input::-webkit-inner-spin-button {
							-webkit-appearance: none;
							margin: 0;
						}
						
						/* Firefox */
						input[type=number] {
							-moz-appearance: textfield;
						}
					}
					
					.slds-form-element:last-child { // Combobox
						width: 40%;
					}
				}
				
				.error-text {
					color: #c23934;
				}
			}
		}
	}

	.message-display {
		.slds-visual-picker_vertical {
			width: 95%;

			.slds-visual-picker__figure {
				height: auto;
				width: auto;
			}
		}
	}

	.slds-visual-picker {
		.slds-input {
			position: relative;
			width: 100%;
			border: 1px solid #dddbda;

			&:active,
			&:focus {
				border-color: #0070d2;
				box-shadow: 0 0 3px #0070d2;
			}
		}

		.slds-listbox {
			.slds-media {
				display: flex;
			}
		}
	}
}
