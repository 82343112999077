.mobilepush-inapp-actvity {
	// Summary view styling
	.summary-view .hub-spoke[data-view="messageTrigger"] {
		.hub-spoke-col__content {
			width: 60%;
		}
		.hub-spoke-col__button {
			width: 15%;
		}
		.custom-summary-view {
			.event-container {
				border: 1px solid #eeeeee;
				padding: 0.5rem 0.75rem;
				margin-bottom: 1rem;
				word-break: break-all;
				
				.configured-attributes {
					line-height: 1.6;
					
					.attribute-container {
						margin-bottom: 0.5rem;
						
						&:first-of-type {
							margin-bottom: 0.25rem;
						}
						
						&:last-of-type {
							margin-bottom: 0px;
						}
						
						.slds-badge {
							margin-right: 0.25rem;
						}
					}
				}
				
				&.full-height {
					.show-more {
						display: none;
					}
					.show-less {
						display: inline-block;
					}
					
					.configured-attributes {
						height: auto;
					}
				}
				
				&.short-height {
					.show-less {
						display: none;
					}
					.show-more {
						display: inline-block;
					}
					
					.configured-attributes {
						max-height: 108px;
						overflow: hidden;
						position: relative;
						
						&:after { // Bottom fade
							content  : "";
							position : absolute;
							z-index  : 1;
							bottom   : 0;
							left     : 0;
							pointer-events   : none;
							background-image : linear-gradient(to bottom, 
							rgba(255,255,255, 0), 
							rgba(255,255,255, 1) 90%);
							width    : 100%;
							height   : 4em;
						}
					}
				}
				
				.show-more {
					display: none;
				}
				
				.show-less {
					display: none;
				}
				
				.event-container-action {
					margin-top: 0.25rem;
					cursor: pointer;
					color: #0070d2;
				}
				
				&:last-of-type {
					margin-bottom: 0px;
				}
				
				.event-name {
					margin-bottom: 0.25rem;
					font-weight: 700;
				}
			}
		}
	}
	
	#messageTrigger {
		height: 100%;
		
		.message-trigger-container {
			height: 85%; // Substracted 15% for the spoke view heading height
			
			.cta-container {
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				
				.heading {
					color: $slds-heading-color;
				}
			}
		}
	}
	
	// Event Selector
	.scm-modal-container {
		.scm-modal-close-button {
			margin-top: 24px;
		}
		
		.scm-modal-content {
			padding: 0px;
			
			#event-selector {
				#event-selector-card {
					border: none;
					box-shadow: none;
					
					.slds-card__header {
						padding-right: 3rem;
						padding-top: 1rem;
						margin-bottom: 1rem;
						margin-top: -2px;
						display: flex;
						align-items: center;
						
						.slds-input-has-icon {
							width: 340px;
							margin-right: 0.5rem;
						}
						
						#event-selector-card__heading {
							.subheading {
								display: flex;
								align-items: center;
								
								span {
									font-size: 14px;
									font-style: italic;
									font-weight: 300;
									color: #706e6b;
								}
								
								.slds-tooltip-trigger {
									margin-left: 0.2rem;
									
									.slds-popover {
										white-space: initial;
										
										.slds-popover__body {
											line-height: 1rem;
										}
									}	
								}
							}
						}
						
						#event-selector-card__header-actions {
							width: auto;
						}
					}
					
					.slds-card__body {
						margin-bottom: 0px;
						position: relative;
						
						thead {
							th {
								text-transform: none;
								font-weight: 400;
								letter-spacing: normal;
								
								.custom-header-cell {
									display: flex;
									align-items: center;
								}
								
								.slds-tooltip-trigger {
									margin-left: 2px;
									
									.slds-button {
										&:focus {
											box-shadow: none;
										}
									}
									
									.slds-popover {
										white-space: initial;
										top: 38px !important;
										
										.slds-popover__body {
											line-height: 1rem;
										}
									}
								}
								
								svg.slds-is-sortable__icon {
									margin-top: -2px;
								}
							}
						}
						
						tbody {
							tr {
								td {
									.slds-radio {
										.slds-radio__label {
											cursor: pointer;
										}
									}
								}
							}
						}
						
						#filter-drawer {
							top: 0;
							right: 0;
							bottom: 0;
							z-index: 1000;
							background: #fff;
							
							.drawer-view {
								position: relative;
								width: 302px;
								box-shadow: rgba(0, 0, 0, 0.25)  -4px 0 3px -1px;
								height: 100%;
								transition: width 250ms;
								border-top: 1px solid rgb(221, 219, 218);
								
								&.hide {
									width: 0;
								}
								
								.drawer-header {
									display: flex;
									width: 302px;
									padding: 15px 20px 10px;
									flex: 0 0 auto;
									align-items: center;
									
									h2 {
										font-size: 16px;
										color: #2a426c;
										flex: 1;
										margin: 0;
									}
									
									.drawer-actions {
										display: flex;
										font-size: 14px;
										align-items: center;
										
										.slds-button {
											&.clear {
												height: 24px;
												line-height: initial;
												margin-right: 8px;
												
												&:focus {
													box-shadow: none;
												}
											}
											
											&.close {
												height: 24px;
												width: 24px;
												
												svg {
													margin-top: -1px;
												}
											}
										}
									}
								}
								
								.drawer-body {
									width: 302px;
									padding: 0 20px 10px;
									flex: 1 1 auto;
									
									.slds-box {
										&.filter-applied {
											border-color: #0070d2;
										}
										
										.body {
											legend {
												padding: 0px;
											}
											
											.slds-radio {
												.slds-radio__label {
													cursor: pointer;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	
	// Event Detail
	.event-detail-container {
		padding-bottom: 20px;
		
		ul[name="events-accordion"] {
			li.slds-accordion__list-item {
				background: #fafaf9;
				border: 1px solid #dddbda;
				border-radius: 0.25rem;
				box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
				
				button.slds-accordion__summary-action {
					&:focus {
						text-decoration: none;
						color: initial;
					}
					&:hover {
						color: initial;
					}
				}
				
				.slds-accordion__summary .slds-truncate {
					width: 100%;
				}
				
				.accordion-summary {
					display: flex;
					align-items: center;
					
					.title {
						font-weight: 700;
					}
					
					&.filter-none {
						.filter.none {
							display: inline-block;
						}
					}
					
					&.filter-applied {
						.filter.applied {
							display: inline-block;
						}
					}
					
					.filter,.event-type {
						font-size: 12px;
						
						.separator {
							margin: 0 0.3rem;
						}
						
						b {
							margin-left: 2px;
						}
					}
					
					.filter {
						display: none;
						margin-left: auto;
						margin-right: 0.75rem;
					}
					
					.event-type {
						display: flex;
						
						.slds-tooltip-trigger {
							margin-left: 2px;
							
							.slds-popover {
								white-space: initial;
								
								.slds-popover__body {
									line-height: 1rem;
								}
							}
						}
					}
				}
				
				.attribute-builder-container {
					.slds-expression {
						.trigger-type-container {
							width: initial;
							
							.slds-form-element__control {
								width: 15rem;
							}
						}
						
						.slds-expression__row {
							.slds-grid {
								.slds-col:not(.slds-grow-none) {
									// Fix width of the value input field so that adding validation message doesn't stretch the width of the input.
									flex: 0 0 480px;
								}
								
								.slds-col:first-child {
									flex: 1 1 auto;
								}
							}
						}
					}
				}
			}
		}
	}
}

// Confirmation Dialog
section.slds-modal.confirmation-dialog {
	.slds-modal__content {
		.slds-p-around_large {
			text-align: center;
		}
	}
}
