#advancedOptions {
	.slds-form-element {
		margin-bottom: 10px;

		.slds-input {
			width: 400px;
		}
	}

	.reporting-id-container {
		.container-heading {
			margin-right: 3px;
		}
		
		margin-bottom: 10px;
	}
}
